<div class="container">
    <div class="row justify-content-md-center text-center">
        <div class="col-sm-12">
            <div>
                <img src="assets/img/banners/profile.jpg" class="rounded-circle m-3" height="100" />
            </div>
        </div>
        <div>
            <h1>Nelson Sousa</h1>
        </div>
    </div>
    <div class="row justify-content-md-center text-left">
        <div class="row justify-content-md-center text-center">
            <div class="col-lg-7" data-aos="fade-down" data-aos-duration="500">
                <ul class="list-group">
                    <li class="list-group-item font-gray">
                        🌐<a href="/" target="_blank">
                            Website
                        </a>
                    </li>
                    <li class="list-group-item font-gray">
                        📹<a href="https://www.youtube.com/channel/UCrIdzsXYG2acp6La93G4Nvg" target="_blank">
                            Youtube Channel
                        </a>
                    </li>
                    <li class="list-group-item font-gray">
                        🏕️<a href="https://youtu.be/8GX43tziyeA?si=cNhHhiF0n2AkDX1X" target="_blank">
                            Solo Camping 15 Days in Alentejo, Portugal
                        </a>
                    </li>
                    <li class="list-group-item font-gray">
                        📱 <a href="https://wa.me/+351913641023" target="_blank">
                            Whatsapp
                        </a>
                    </li>
                    <li class="list-group-item font-gray">
                        ✉️<a href="mailto:work@nelsonrsousa.com" target="_blank">
                            Email
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>