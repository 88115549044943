import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Componentes
import { MenuComponent } from './components/menu/menu.component';
import { TrabalhoComponent } from './pages/trabalho/trabalho.component';
import { BioComponent } from './pages/bio/bio.component';
import { FooterComponent } from './components/footer/footer.component';
import { TravelsComponent } from './pages/travels/travels.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { WorkDetailComponent } from './pages/work-detail/work-detail.component';
import { DroneComponent } from './pages/drone/drone.component';
import { CountriesMapModule } from 'countries-map';
import { WorldmapComponent } from './pages/worldmap/worldmap.component';
import { MadeiraComponent } from './pages/travels-pages/madeira/madeira.component';
import { MoroccoComponent } from './pages/travels-pages/morocco/morocco.component';
import { PicosComponent } from './pages/travels-pages/picos/picos.component';
import { JordanComponent } from './pages/travels-pages/jordan/jordan.component';
import { LeafletModule } from '@asymmetrik/angular2-leaflet';
import { ImageTitleDirective } from './image-title.directive';
import { LinksComponent } from './pages/links/links.component';
import { FpvComponent } from './pages/fpv/fpv.component';
import { InterrailComponent } from './pages/travels-pages/interrail/interrail.component';
import { PrintsComponent } from './pages/prints/prints.component';
import { NorwayComponent } from './pages/travels-pages/norway/norway.component';
import { PhotoComponent } from './pages/photo/photo.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TimelineComponent } from './components/timeline/timeline.component';
import { TimelinePageComponent } from './timeline-page/timeline-page.component';
import { AlpsComponent } from './pages/travels-pages/alps/alps.component';
import { AccommodationPricesComponent } from './pages/prices/accommodation-prices/accommodation-prices.component';
import { WeddingsComponent } from './pages/weddings/weddings.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    HomepageComponent,
    FooterComponent,
    ContactsComponent,
    SafePipe,
    PicosComponent,
    BioComponent,
    TrabalhoComponent,
    MadeiraComponent,
    MoroccoComponent,
    TravelsComponent,
    WorkDetailComponent,
    DroneComponent,
    WorldmapComponent,
    JordanComponent,
    ImageTitleDirective,
    LinksComponent,
    FpvComponent,
    InterrailComponent,
    PrintsComponent,
    NorwayComponent,
    PhotoComponent,
    TimelineComponent,
    TimelinePageComponent,
    AlpsComponent,
    AccommodationPricesComponent,
    WeddingsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CountriesMapModule,
    LeafletModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
