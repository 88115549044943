<div class="container text-center mt-5">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">


        <div class="row justify-content-center text-center">
            <div class="col-sm-8 mb-5">
                <h1 class="dillan">Madeira</h1>
                <p><i>Out 2021 // Mar 2022 // Jun 2023 </i></p>
                
            </div>
        </div>

        <div class="row d-flex justify-content-center pb-5 mb-5">
            <div class="col-sm-10" >
                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" src="https://www.youtube.com/embed/_FvW4kuHZl8?start=59" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>
        
        <div class="row vertical-photos">
            <div class="col-lg-5">
                <img src="assets/img/travels/2022/madeira/madeira-2.jpg" alt="" class="img-fluid" />
            </div>
           
            <div class="col-lg-7">
                <img src="assets/img/travels/2022/madeira/madeira-4.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-1.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-12.jpg" alt="" class="img-fluid" />
            </div>
        </div>

       

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2022/madeira/madeira-5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/travels/2022/madeira/madeira-6.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-8.jpg" alt="" class="img-fluid" />
            </div>
          
           
        </div>


        <div class="row">
            
            <div class="col-lg-12">
                <img src="assets/img/travels/2022/madeira/madeira-9.jpg" alt="" class="img-fluid" />

            </div>
           
        </div>

        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-10.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/madeira-11.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-8">
                <img src="assets/img/travels/2022/madeira/2023/madeira-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/travels/2022/madeira/2023/madeira-8.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/2023/madeira-3.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/2023/madeira-4.jpg" alt="" class="img-fluid" />
            </div>
             
        </div>

        <div class="row">

            <div class="col-lg-12">
                <img src="assets/img/travels/2022/madeira/2023/madeira-5.jpg" alt="" class="img-fluid" />
            </div>
    
        </div>

        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/2023/madeira-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/madeira/2023/madeira.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row ">

            <div class="col-lg-5">
                <img src="assets/img/travels/2022/madeira/2023/madeira-10.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2022/madeira/2023/madeira-9.jpg" alt="" class="img-fluid" />
            </div>
            
        </div>

</div>