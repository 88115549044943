import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-travels',
  templateUrl: './travels.component.html',
  styleUrls: ['./travels.component.css']
})
export class TravelsComponent implements OnInit {

  constructor() { }


  travels =[ 
    {
      url: "2023/alps",
      img: "assets/img/travels/2023/alps/alps-18.jpg",
      name: "TRAVEL_ALPS",
      show: true,
      col: 12,
    },
    {
      url: "2023/norway",
      img: "assets/img/travels/2023/norway/norway-47.jpg",
      name: "TRAVEL_NORWAY",
      show: true,
      col: 6,
    },
    {
      url: "2023/interrail",
      img: "assets/img/travels/2023/interrail/thumb.jpg",
      name: "TRAVEL_INTERRAIL",
      show: true,
      col: 6,
    },
    {
      url: "2023/jordan",
      img: "assets/img/travels/2023/jordan/jordan2.jpg",
      name: "TRAVEL_JORDAN",
      show: true,
      col: 6,
    },
    {
      url: "2022/morocco",
      img: "assets/img/travels/2022/morocco/banner.jpg",
      name: "TRAVEL_MOROCCO",
      show: true,
      col: 6,

    }, 
    {
      url: "2022/picos",
      img: "assets/img/travels/2022/picos/picos.jpg",
      name: "TRAVEL_PICOS",
      show: true,
      col: 6,

    },
    {
      url: "2022/madeira",
      img: "assets/img/travels/2022/madeira/banner.jpg",
      name: "TRAVEL_MADEIRA",
      show: true,
      col: 6,
    }
  ]

  ngOnInit(): void {
    AOS.init();
  }

}
