<div class="container text-center mt-5">
    <div class="row justify-content-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">2022</h1>
            <p>Foi em Abril de 2021 que fiz a minha primeira viagem aos picos e não consegui registar praticamente nada.
                Foi uma viagem entre amigos da empresa onde trabalhei e serviu essencialmente para conhecer alguns os
                principais trilhos e locais desta zona. Tentei subir o Pico Gilbo (sem sucesso por causa da neve), fiz
                apenas 1/3 da Ruta Del Cares mas tive de voltar para trás por causa da chuva. Nessa viagem ainda
                consegui visitar os Lagos de Covadonga num dia de sol. Em Outubro de 2022 voltei, desta vez rodeado de
                pessoas com a mesma paixão que eu: Montanhas e Câmaras. Uma viagem repleta de trilhos (5 dias , 5
                trilhos). Foram eles: Ojo de Buey, Poncebos - Bulnes, Ruta del Cares, Lagos de Covadonga e por fim a
                ascensão ao Pico Gilbo (momento onde vi um dos por-do-sol mais bonitos da minha vida).
            </p>
        </div>
    </div>
</div>


<app-timeline [cities]="cities"></app-timeline>

<div class="container">
    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/doME8hmyfh4' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/xgWyNu5fjqA' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-13.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-6.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-16.jpg" alt="" class="img-fluid" />
            </div>

        </div>


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-14.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>



        <div class="row">


            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-8.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-15.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-12.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-17.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">


            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-3.jpg" alt="" class="img-fluid" />
            </div>



        </div>

        <div class="row">
            <div class="col-lg-7">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img appImageTitle src="assets/img/travels/2022/picos/picos-10.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <h3 class="dillan text-center m-5">
            A equipa
        </h3>

        <div class="row profile justify-content-center my-5">
            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/nelsonrsousa/">
                    <img src="assets/img/travels/2022/picos/nelson.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/brasdio/">
                    <img src="assets/img/travels/2022/picos/bras.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2  text-center">
                <a target="_blank" href="https://www.instagram.com/r.cortinhas/">
                    <img src="assets/img/travels/2022/picos/cortinhas.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2  text-center">
                <a target="_blank" href="https://www.instagram.com/manelpessanha/">
                    <img src="assets/img/travels/2022/picos/pessanha.jpg" alt="" class="img-fluid" />
                </a>
            </div>
        </div>


    </div>

</div>


<div class="container text-center mt-5">
    <div class="row justify-content-center">

        <div class="col-sm-8 mb-5">
            <h1 class="dillan">2024</h1>
            <p>
                Voltei aos Picos de Europa no Verão de 2024. Numa viagem sozinho, a objetivo era começar a explorar a zona do maçico central e iniciar-me em trilhos de vários dias em auto-suficiência. Estacionei a carrinha em Fuente De e comecei a minha jornada pelas montanhas. Fui até ao maravilhoso refúgio Collado Hermoso onde passei a noite. De seguida deixei o Refúgio e fui até a Cabana Veronica , atravessando as montanhas num trilho altamente técnico e rodeado de escarpas. Voltei ainda no mesmo dia para Fuente Dé. O próximo passo foi ir até ao Refugio de Urriellu, mais um lugar icónico nos Picos de Europa. Depois de dois dias à espera que o tempo desse tréguas, iniciei a minha caminhada em Sotres em direção ao Naranjo de Bulnes, a montanha mais conhecida dos Picos de Europa.            </p>
        </div>

    </div>
</div>


<app-timeline [cities]="spots2024"></app-timeline>


<div class="container">
    <div class="travel" data-aos="fade-up" data-aos-duration="1000">


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-2.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-4.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-5.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-7.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-14.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-13.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-15.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row ">
            <div class="col-lg-4">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-17.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-20.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-23.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-29.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-25.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-10.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img appImageTitle src="assets/img/travels/2024/picos/picos-12.jpg" alt="" class="img-fluid" />
            </div>

        </div>



    </div>

</div>