import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-weddings',
  templateUrl: './weddings.component.html',
  styleUrls: ['./weddings.component.css']
})
export class WeddingsComponent implements OnInit {

  constructor() { }

  weddingVideos: string[] = [
   "https://player.vimeo.com/video/1033951557",
  ]

  ngOnInit(): void {
  }

}
