<div class="container text-center mt-5">

    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Casamentos</h1>
        </div>
    </div>

</div>


<div class="container">

    <div class="travel" >

        <div class="row d-flex justify-content-center pb-5 mb-5">
            <div class="col-sm-10" >
                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" src="https://player.vimeo.com/video/1033951557" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

</div>