import { Component, OnInit } from '@angular/core';
import AOS from "aos";

@Component({
  selector: 'app-norway',
  templateUrl: './norway.component.html',
  styleUrls: ['./norway.component.css']
})
export class NorwayComponent implements OnInit {



  moments = [
    {
      number:1,
      class: "rotate1",
    },
    {
      number:2,
      class: "rotate2",
    },
    {
      number:3,
      class: "rotate1",
    },
    {
      number:4,
      class: "rotate3",
    },
    {
      number:5,
      class: "rotate2",
    },
    {
      number:6,
      class: "rotate1",
    },
    {
      number:7,
      class: "rotate3",
    },
    {
      number:8,
      class: "rotate3",
    },
    {
      number:9,
      class: "rotate1",
    },
    {
      number:10,
      class: "rotate2",
    },
    {
      number:11,
      class: "rotate1",
    }
  ]

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }



}
