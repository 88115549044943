<div class="container mb-5" data-aos="fade-up">

    <div class="row justify-content-center align-items-center">

        <div class="col-md-2">
            <img src="assets/img/me/me.jpg" class="img-fluid rounded-circle" alt="">
        </div>
    </div>

    <div class="row py-5 justify-content-center align-items-center text-center">

        <div class="col-md-6 px-4">

            <h1 class="dillan">Sobre mim</h1>
            <p class="pb-4">Nelson Sousa, Porto, Portugal</p>

            <p>
                Desde criança, sempre me interessei por tecnologia, o que me levou a estudar engenharia informática. No
                entanto, também sempre fui curioso sobre fotografia e vídeo desde cedo. Comecei a mexer em câmeras em
                2010, quando praticava fingerboard com amigos e adorava filmar os nossos truques e encontros.
                <br><br>
                Nas férias de verão, comecei a criar vídeos a com a minha família e amigos. Em 2021, decidi investir
                mais
                nesta paixão e finalmente comprei uma câmera profissional. Desde então, tenho trabalhado em diversos
                projetos
                comerciais e corporativos. Gosto de viajar, explorar a natureza e contar histórias dessas aventuras por
                meio de imagens. Acredito que a fotografia e o vídeo são formas incríveis de transmitir emoções e
                relatar acontecimentos. Estou sempre em busca de novas oportunidades para melhorar as minhas habilidades
                e
                partilhar as minhas criações com o mundo.
            </p>
        </div>
    </div>

    <div class="row py-5 justify-content-center align-items-center my-5" >

        <div class="col-md-6">

            <img src="assets/img/me/caminhos.jpg" class="img-fluid"/>
        </div>

        <div class="col-md-6 p-5">
            <h1 class="dillan">Caminhos de Santiago</h1>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
            </p>
        </div>
        
        
    </div>

    <div class="row py-5 justify-content-center align-items-center my-5" >


        <div class="col-md-6 p-5">

            <h1 class="dillan">Intrarail</h1>
            <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
            </p>
        </div>

        <div class="col-md-6">

            <img src="assets/img/me/intrarail.jpg" class="img-fluid"/>
        </div>        
        
    </div>


    <!-- 
    <div class="showcase mt-5" data-aos="fade-up" data-aos-duration="1000">
        <div class="row row-cols-2 row-cols-md-3 row-cols-lg-5 row-cols-xl-4">
            <div *ngFor="let photo of photos" class="col p-1 m-0">
                <div class="square-image-wrapper" >
                    <img [src]="photo" alt="" class="square-image" />
                </div>
            </div>
        </div>
    </div>
    -->
</div>