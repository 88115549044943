<div class="container text-center mt-5">

    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Interrail Balcãs</h1>
            <p><i>Abr 2023</i></p>

            <p>
                Após fazer um intrarail em Portugal onde tive a oportunidade de percorrer o país de norte a sul de comboio, sempre tive um grande interesse em realizar um Interrail pela Europa 
            </p>
            <p>
                A ideia seria fazer algo diferente do comum, da tradicional viagem pela Europa Central. Tinha duas opções em cima da mesa: Nórdicos ou Balcãs. Uma vez que nesse mesmo ano já tinah uma viagem marcada para as Ilhas Lofoten, acabei por optar pelos Balcãs.
            </p>

            <p>
                Voei para Nuremberga, Alemanha e comecei aí a minha jornada. A rota passava pela Áustria, Eslovénia, Croácia, Bósnia, Sérvia, Montenegro, Albânia.
            </p>

            <p>
                Apesar de ser amante de montanhas , acima de tudo sou amante de aventuras e descoberta. E por isso, o grande destque desta viagem vai para a Bósnia, mais concretamente a sua capital: Sarajevo. Foi uma cidade diferente e que me supreendeu em muitos aspetos mas em especial pelo seu contexto histórico e religioso. 
            </p>
            
        </div>
    </div>


</div>


<app-timeline [cities]="cities">
</app-timeline>

<div class="container">
    <div class="masonry-container">
        <div  *ngFor="let item of images" class="masonry-item">
            <img [src]="this.mainUrl + item + '.jpg' " alt=""  />
        </div>
    </div>
</div>