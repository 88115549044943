<div *ngIf="isMobile && !showPicture" class="bars-container">
  <span (click)="toggleMenu()" >
    <i class="bi bi-list"></i>
  </span>
</div>

<div class="container" *ngIf="showMenu">
  
  <div class="d-flex flex-column flex-md-row align-items-center menu">
    <a href="/" class="d-flex align-items-center text-dark text-decoration-none">
      <h1>Nelson Sousa</h1>
    </a>
  
    <nav class="d-inline-flex mt-2 mt-md-0 ms-md-auto" *ngIf="!isMobile">
      
      <a *ngFor="let item of menu"  class="me-4 py-2 text-decoration-none"  [href]="item.url">
          {{ item.name | translate }}
      </a>

      <a class="py-2 text-dark text-decoration-none ps-4 cursor-pointer" [ngClass]="  this.currentLang == 'pt' ? 'selected' : '' " (click)="onChangeLanguage('pt')">
      PT 
      </a>
      <a class="py-2 px-1 text-dark text-decoration-none ">
      |
      </a>

      <a class="py-2 text-dark text-decoration-none cursor-pointer" [ngClass]="  this.currentLang == 'en' ? 'selected' : '' " (click)="onChangeLanguage('en')">
      EN
      </a>
      
    </nav>
  </div>
</div>

<div class="menu-fullscreen" *ngIf="this.menuOpen">
  <div class="close" (click)="toggleMenu()">
    <i class="bi bi-x-lg"></i>
  </div>
  <div class="d-flex justify-content-center align-items-center" style="min-height: 100vh;">
    <ul class="list-group list-group-flush text-center">
      <li *ngFor="let item of menu" class="list-group-item">
        <a [href]="item.url"> 
          {{ item.name | translate }}
        </a>
      </li>
     
      <div class="row socials justify-content-center mt-4">
        <div class="col-auto">
          <div class="float-end">
            <span class="cursor-pointer lang" [ngClass]="currentLang == 'pt' ? 'selected' : ''" (click)="onChangeLanguage('pt')">PT</span>/
            <span class="cursor-pointer lang" [ngClass]="currentLang == 'en' ? 'selected' : ''" (click)="onChangeLanguage('en')">EN</span>
          </div>
        </div>
      </div>
      
    </ul>
  </div>
</div>