import { Component, OnInit } from '@angular/core';
import AOS from "aos";


@Component({
  selector: 'app-picos',
  templateUrl: './picos.component.html',
  styleUrls: ['./picos.component.css']
})
export class PicosComponent implements OnInit {

  cities : string [] = [
    "Ojo de Buey",
    "Bulnes",
    "Ruta del Cares",
    "Covadonga",
    "Riaño"
  ]

  spots2024 : string [] = [
    "Collado Hermoso",
    "Cabana Veronica",
    "Fuente De",
    "Sotres",
    "Refugio Urielu"
  ]

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
